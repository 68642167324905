/* eslint-disable react/forbid-foreign-prop-types */
import React, { useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'

import { camelToTitleCase, mergeSlotProps } from '@shared/utils'

import CopyIconButton from '@components/CopyIconButton'

HookInputControl.propTypes = {
  /** An input/control component to render */
  children: PropTypes.node.isRequired,

  /** The field name */
  field: PropTypes.string.isRequired,

  /** Helper text to show when no error messages */
  helperText: PropTypes.string,

  /** Inputs can be highlighted when the value is missing */
  missing: PropTypes.bool,

  /**
   * A function to determine if the value is missing for the custom comparisons
   *
   * @example if value is boolean missing would be:
   * isMissingFn: (value) => [undefined, null].includes(value)
   * */
  isMissingFn: PropTypes.func,

  /** Can disable automatically appending (optional) on non-required fields */
  disableOptionalLabel: PropTypes.bool,

  /** Hide the generated label */
  hideLabel: PropTypes.bool,

  /** Show the children label */
  showChildrenLabel: PropTypes.bool,

  /** Stack props */
  stackProps: PropTypes.object,
}

/**
 * A wrapper/helper to use React hook form with a component.
 *
 * This will inject a number of properties into the child component that are used
 * by react hook form to provide the standard behavior.
 * The "field" value will be used for the id, name, label, data-testid.
 * This wrapper is meant to be used with a FormProvider, so it has access to
 * context and controller.
 *
 * @example
 * <HookInputControl field="doctorName">
 *   <TextField required />
 * </HookInputControl>
 */
export default function HookInputControl({
  children,
  field: name,
  clipboard = false,
  missing = false,
  isMissingFn,
  disableOptionalLabel = false,
  hideLabel = false,
  showChildrenLabel = false,
  stackProps = {},
  helperText,
}) {
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control })

  // Pull any known props from the child for re-use
  const childRequired = children.props.required || false
  const childLabel = children.props.label
  const childOnChange = children.props.onChange
  const childOnBlur = children.props.onBlur
  const disabled = children.props.disabled

  const error = fieldState.isTouched && Boolean(fieldState.error)
  const value = field.value

  // Calculate the label 'type'
  const label = useMemo(() => {
    const showOptional = !childRequired && !disableOptionalLabel

    // If label not provided, use the field name
    if (!childLabel) {
      return `${camelToTitleCase(field.name)}${showOptional ? ' (optional)' : ''}`
    }

    // If label is provided, and it is a string type, use it and extend with optional if not required
    if (typeof childLabel === 'string') {
      return `${childLabel}${showOptional ? ' (optional)' : ''}`
    }

    // If label is provided, and it is not a string type, assume it is a component and return it
    return childLabel
  }, [childLabel, childRequired, disableOptionalLabel, field.name])

  const isMissing = useMemo(() => {
    if (!missing) return false
    if (isMissingFn) return isMissingFn(value)
    return !value
  }, [isMissingFn, missing, value])

  const slotProps = useMemo(
    () =>
      mergeSlotProps(
        {
          formHelperText: { 'data-testid': `helper-${field.name}` },
          htmlInput: { required: false, 'data-testid': `input-${field.name}` },
        },
        children.props.slotProps
      ),
    [children.props.slotProps, field.name]
  )

  const additionalProps = {
    inputRef: field.ref,
    required: childRequired,
    variant: 'outlined',
    id: field.name,
    name: field.name,
    value,
    label: showChildrenLabel ? label : undefined,
    onChange: childOnChange ?? field.onChange,
    onBlur: childOnBlur ?? field.onBlur,
    error,
    helperText: (fieldState.isTouched && fieldState.error?.message) || helperText || '',
    autoComplete: 'off',
    slotProps,
  }

  return (
    <Stack spacing={1} {...stackProps} sx={{ backgroundColor: isMissing ? 'warning.lighter' : 'initial' }}>
      {label && !hideLabel && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <InputLabel htmlFor={field.name} error={error} disabled={disabled} sx={{ color: isMissing ? 'warning.darker' : 'initial' }}>
            {label}
          </InputLabel>
          {clipboard && <CopyIconButton fn={(copy) => copy(value)} />}
        </Stack>
      )}
      {React.cloneElement(children, additionalProps)}
    </Stack>
  )
}
