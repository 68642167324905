import { useState } from 'react'
import dayjs from 'dayjs'

import AvatarMui from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { AppointmentScheduleTypes, AppointmentTypes } from '@shared/utils'

import { SchedTypeLabel } from '@pages/Appointments/Appointments.utils'
import {
  CalendarOutlinedIcon,
  ExperimentOutlinedIcon,
  MedicineBoxOutlinedIcon,
  MoreOutlinedIcon,
  PillIcon,
  UserAddOutlinedIcon,
} from '@icons'
import Avatar from '@components/Avatar'

import {
  useMarkAllRead,
  useNotificationOnClick,
  useNotificationUpdate,
  useUnreadNotificationsCount,
  useUserNotifications,
} from './Notifications.hooks'
import { NotificationType } from './Notifications.utils'

export default function NotificationsPopover({ anchorEl, open = false, onClose }) {
  const [tab, setTab] = useState('unread')

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useUserNotifications({
    read: tab === 'unread' ? false : undefined,
  })

  const unreadNotificationsCount = useUnreadNotificationsCount()

  const markAllRead = useMarkAllRead()
  const update = useNotificationUpdate()

  const showEmpty = !data || data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      slotProps={{
        paper: {
          sx: { width: '440px' },
          'data-testid': 'notifications-popover',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', px: 3, py: 2 }}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', flex: '1 1 auto' }}>
          <Typography variant="h4">Notifications</Typography>
          {unreadNotificationsCount && <Chip label={unreadNotificationsCount} color="error" variant="light" size="small" />}
        </Stack>
        <Button
          onClick={() => markAllRead.mutateAsync()}
          loading={markAllRead.isPending}
          size="small"
          sx={{ textTransform: 'none' }}
          data-testid="notifications-mark-clear"
        >
          Mark all as read
        </Button>
      </Stack>
      <Tabs value={tab} onChange={(e, tab) => setTab(tab)} sx={{ px: 2 }}>
        <Tab label="Unread" value="unread" data-testid="notifications-unread-tab" />
        <Tab label="All" value="all" data-testid="notifications-all-tab" />
      </Tabs>
      <Divider />
      {showEmpty && (
        <Box sx={{ p: 2 }} data-testid="notifications-empty">
          <Typography variant="h5" align="center" sx={{ color: 'text.secondary', height: 50, fontWeight: 'normal' }}>
            Nothing to see here!
          </Typography>
        </Box>
      )}
      {showData && (
        <Box sx={{ maxHeight: '360px', overflowY: 'auto' }}>
          <List disablePadding data-testid="notifications-list">
            {data.map((notification, index) => (
              <NotificationItem
                key={notification.id}
                divider={index < data.length - 1}
                notification={notification}
                onUpdate={update.mutate}
                onClose={onClose}
              />
            ))}
            {hasNextPage && (
              <Button
                fullWidth
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                endIcon={<MoreOutlinedIcon rotate={90} />}
                loadingPosition="end"
                sx={{ textTransform: 'none' }}
                data-testid="notifications-more"
              >
                more
              </Button>
            )}
          </List>
        </Box>
      )}
    </Popover>
  )
}

function NotificationItem({ divider, notification, onUpdate, onClose }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event) => setAnchorEl(event?.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const onClick = useNotificationOnClick(notification)

  return (
    <ListItemButton
      divider={divider}
      onClick={() => {
        onClick()
        onClose()
      }}
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        transition: 'opacity 0.4s ease-in-out',
        opacity: notification.read ? 0.5 : 1,
        pr: 1,
      }}
      data-testid={`notification-item-${notification.id}`}
    >
      <NotificationContent notification={notification} />
      <IconButton
        component="div"
        onClick={(e) => {
          e.stopPropagation()
          handleMenuOpen(e)
        }}
      >
        <MoreOutlinedIcon />
      </IconButton>
      <Menu
        id={`notification-menu-${notification.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          e.stopPropagation()
          handleMenuClose()
        }}
        MenuListProps={{ 'aria-labelledby': 'notification-menu-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            onUpdate({ id: notification.id, read: !notification.read })
            handleMenuClose()
          }}
        >
          Mark as {notification.read ? 'unread' : 'read'}
        </MenuItem>
      </Menu>
    </ListItemButton>
  )
}

export function NotificationContent({ notification }) {
  if (!notification) return null

  const config = notificationTypeConfig[notification.type]
  if (!config) return null

  const { icon, getMessage } = config

  const testId = `notification-item-${notification.id}-${notification.type.toLowerCase()}`

  return (
    <Stack direction="row" spacing={1} data-testid={testId}>
      <Box sx={{ pt: 0.5 }}>
        {![NotificationType.Message, NotificationType.Availabilities].includes(notification.type) ? (
          <AvatarMui sx={{ backgroundColor: 'background.default', boxShadow: (theme) => theme.customShadows.z2, color: 'text.primary' }}>
            {icon}
          </AvatarMui>
        ) : notification.author ? (
          <Avatar variant="circular" user={notification.author} />
        ) : null}
      </Box>
      <div>
        <Typography variant="body2" data-testid={`${testId}-content`}>
          {getMessage(notification)}
        </Typography>
        {notification.createdAt && (
          <Typography variant="caption" data-testid={`${testId}-date`} sx={{ color: 'text.secondary' }}>
            {dayjs(notification.createdAt).format('MMM D, hh:mm A')}
          </Typography>
        )}
      </div>
    </Stack>
  )
}

const notificationTypeConfig = {
  [NotificationType.Message]: {
    icon: null,
    getMessage: (notification) => (
      <>
        <b>{notification.author?.fullName || 'User'}</b> {notification.properties.replyingMessage ? 'responded to you' : 'mentioned you'} in
        the patient's internal chat (MRN&nbsp;{notification.properties.patientId})
      </>
    ),
  },
  [NotificationType.DoseSpot]: {
    icon: <PillIcon />,
    getMessage: () => 'You have a new DoseSpot notification, click to open',
  },
  [NotificationType.QuestLabQuestionnaire]: {
    icon: <ExperimentOutlinedIcon />,
    getMessage: (notification) => `You have a new Quest lab notification. Click to view lab #${notification.properties.id}`,
  },
  [NotificationType.ReassignedPatient]: {
    icon: <UserAddOutlinedIcon />,
    getMessage: (notification) => (
      <>
        <b>{notification.properties.patientName}</b> MRN {notification.properties.patientId} in <b>{notification.properties.cboName}</b> has
        been reassigned to you
      </>
    ),
  },
  [NotificationType.PatientUpdatedCBO]: {
    icon: <MedicineBoxOutlinedIcon />,
    getMessage: (notification) => (
      <>
        <b>{notification.properties.patientName}</b> MRN {notification.properties.patientId} has been updated to{' '}
        <b>{notification.properties.cboName}</b> CBO
      </>
    ),
  },
  [NotificationType.Availabilities]: {
    icon: <CalendarOutlinedIcon />,
    getMessage: (notification) => {
      const format = 'YYYY-MM-DDTHH:mm'
      const start = dayjs(notification.properties.startTimeTz, format)
      const end = dayjs(notification.properties.endTimeTz, format)
      return (
        <>
          <b>{notification.author?.fullName || 'User'}</b> has declined your availability request for {start.format('MMM D')} from{' '}
          {start.format('LT')} to {end.format('LT')}
        </>
      )
    },
  },
  [NotificationType.NewAppointment]: {
    icon: <CalendarOutlinedIcon />,
    getMessage: (notification) => {
      const { patientId, patientName, appointmentSchedType, encounterId, encounterDescription } = notification.properties

      return (
        <>
          You have a new <b>{SchedTypeLabel[appointmentSchedType]} Appointment</b> with the <b>{patientName}</b> MRN {patientId} in the{' '}
          {encounterDescription} Encounter #{encounterId}
        </>
      )
    },
  },
}
