import { useEffect, useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useReadLocalStorage } from 'usehooks-ts'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { FeatureFlag, useFeatureFlag } from '@shared/providers/src/FeatureFlagsProvider'
import API from '@shared/services/src/API'
import { AppointmentScheduleTypes, AppointmentStatus, BuildEnv, pageParam, QK, TreatmentType } from '@shared/utils'

import useBusinessHours from '@hooks/useBusinessHours'

import { CalendarEvent, eventsFactory, format, ScheduleType } from './Calendar.utils'

const LIMIT = 50
const isProd = import.meta.env.VITE_BUILD_ENV === BuildEnv.Production

export function useCalendarData({ provider, date, scheduleType }) {
  const providerId = provider?.id
  const timezone = provider?.timezone

  const { data: businessHours, isPending: areBusinessHoursPending } = useBusinessHours()
  const { data: completedEncounters, isFetching: areCompletedEncountersLoading } = useCompletedEncounters(providerId, date)
  const { data: availabilities, isFetching: areAvailabilitiesLoading } = useWeekAvailability(providerId, timezone, date)
  const { data: adminTimes, isFetching: areAdminTimesLoading } = useWeekAdminTimes(providerId, timezone, date)
  const { data: appointments, isFetching: areAppointmentsLoading } = useWeekAppointments(providerId, timezone, date, scheduleType)
  const { data: adHocAppointments, isFetching: areAdHocAppointmentsLoading } = useWeekAdHocAppointments(providerId, timezone, date)
  const { data: blackoutPeriods, isFetching: areBlackoutPeriodsLoading } = useBlackoutPeriods(providerId, timezone, date, businessHours)

  const isLoading = useLoadingState(
    areCompletedEncountersLoading ||
      areAppointmentsLoading ||
      areAdHocAppointmentsLoading ||
      areAdminTimesLoading ||
      areAvailabilitiesLoading ||
      areBlackoutPeriodsLoading ||
      areBusinessHoursPending
  )

  const events = useMemo(() => {
    return []
      .concat(availabilities || [])
      .concat(adminTimes || [])
      .concat(appointments?.events || [])
      .concat(adHocAppointments?.events || [])
      .concat(blackoutPeriods || [])
  }, [availabilities, adminTimes, appointments, adHocAppointments, blackoutPeriods])

  return {
    events,
    isLoading,

    businessHours,
    completedEncounters,
    availabilities,
    adminTimes,
    appointments,
    adHocAppointments,
    blackoutPeriods,
  }
}

function useWeekAvailability(providerId, timezone, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const availabilityAuthorizationEnabled = useFeatureFlag(FeatureFlag.AvailabilityAuthorization)

  const limit = isProd ? LIMIT : overriddenLimit

  const enabled = Boolean(providerId)
  const query = {
    limit,
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).availabilities.list(query),
    queryFn: ({ pageParam }) => API.providers.id(providerId).availabilities.list({ ...query, offset: pageParam * limit }),
    enabled,
    select: ({ pages }) => {
      const data = pages.flat().map((a) => (availabilityAuthorizationEnabled ? a : { ...a, approved: true }))
      return eventsFactory(data, CalendarEvent.Availability, timezone)
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(enabled, queryResult)

  return queryResult
}

function useWeekAdminTimes(providerId, timezone, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const enabled = Boolean(providerId)
  const query = {
    limit,
    start_date_time: date.startOf('week').format(format),
    end_date_time: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).adminTimes.list(query),
    queryFn: ({ pageParam }) => API.providers.id(providerId).adminTimes.list({ ...query, offset: pageParam * limit }),
    enabled,
    select: ({ pages }) => {
      const data = pages.flat()
      return eventsFactory(data, CalendarEvent.AdminTime, timezone)
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(enabled, queryResult)

  return queryResult
}

function useWeekAppointments(providerId, timezone, date, scheduleType) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const enabled = Boolean(providerId)
  const query = {
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
    appointment_status: [
      AppointmentStatus.Missed,
      AppointmentStatus.Scheduled,
      AppointmentStatus.Waiting,
      AppointmentStatus.InProgress,
      AppointmentStatus.Documenting,
      AppointmentStatus.Complete,
    ],
    sched_type: scheduleType === ScheduleType.All ? undefined : AppointmentScheduleTypes.Scheduled,
    limit,
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).appointments.list(query),
    queryFn: ({ pageParam }) => API.providers.id(providerId).appointments.list({ ...query, offset: pageParam * limit }),
    enabled,
    select: ({ pages }) => {
      const data = pages.flat()
      const events = eventsFactory(data, CalendarEvent.Appointment, timezone)
      return { data, events }
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(enabled, queryResult)

  return queryResult
}

function useWeekAdHocAppointments(providerId, timezone, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const enabled = Boolean(providerId)
  const query = {
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
    appointment_status: [
      AppointmentStatus.Missed,
      AppointmentStatus.Scheduled,
      AppointmentStatus.Waiting,
      AppointmentStatus.InProgress,
      AppointmentStatus.Documenting,
      AppointmentStatus.Complete,
    ],
    limit,
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).adHocAppointments.list(query),
    queryFn: ({ pageParam }) => API.providers.id(providerId).appointments.adHoc.list({ ...query, offset: pageParam * limit }),
    enabled,
    select: ({ pages }) => {
      const data = pages.flat()
      const events = eventsFactory(data, CalendarEvent.AdHocAppointment, timezone)
      return { data, events }
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(enabled, queryResult)

  return queryResult
}

function useBlackoutPeriods(providerId, timezone, date, businessHours) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const enabled = Boolean(providerId) && Boolean(businessHours)
  const query = {
    limit,
    start_date_time: date.startOf('week').format(format),
    end_date_time: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.platform.blackouts.list(query),
    queryFn: ({ pageParam }) => API.platform.blackouts.list({ ...query, offset: pageParam * limit }),
    enabled,
    select: ({ pages }) => {
      const data = pages.flat()
      return eventsFactory(data, CalendarEvent.Blackout, timezone, { businessHours })
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(enabled, queryResult)

  return queryResult
}

function useCompletedEncounters(providerId, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const enabled = Boolean(providerId)
  const query = {
    limit,
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).completedEncounters.list(query),
    queryFn: ({ pageParam }) => API.providers.id(providerId).encounters.completed({ ...query, offset: pageParam * limit }),
    enabled,
    select: (data) => data.pages.flat().length,
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(enabled, queryResult)

  return queryResult
}

function useInfiniteScroll(enabled, queryResult) {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useEffect(() => {
    if (enabled && !isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [enabled, fetchNextPage, hasNextPage, isFetchingNextPage])
}
