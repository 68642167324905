import { keepPreviousData } from '@tanstack/react-query'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { handleErrorSilently, OnDemandStatus, UserRole } from '@shared/utils'

import CopyIconButton from '@components/CopyIconButton'
import { spacing } from '@components/Details/Details.utils'
import { usePatientAccessible } from '@components/PatientAccessGuard'

import NotesSection from '../AppointmentDetailsModal/components/NotesSection'
import PatientSection from '../AppointmentDetailsModal/components/PatientSection'
import AppointmentSection from './components/AppointmentSection'
import DetailsSection from './components/DetailsSection'
import { useOnDemand } from './OnDemandDetailsModal.hooks'

export default function OnDemandDetailsModal({ id, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
    >
      <Content id={id} onClose={onClose} />
    </Dialog>
  )
}

function Content({ id, onClose }) {
  const me = useMe()
  const query = useOnDemand(
    id,
    {
      enabled: Boolean(id),
      placeholderData: keepPreviousData,
    },
    { skipHandling: true }
  )

  useQueryEvents(query, {
    onError: (error) => {
      handleErrorSilently(error)
      return onClose()
    },
  })

  const { data } = query
  const patientAccessible = usePatientAccessible(data?.userId)
  const isAllowed = isUserAllowed(me, [UserRole.Admin, UserRole.ClinicalLead, UserRole.Provider, UserRole.MA, UserRole.Support])
  const canAddNotes = isAllowed && patientAccessible
  const showData = Boolean(data)

  return (
    <>
      <DialogTitle onClose={onClose}>
        {showData ? (
          <Stack spacing={-0.5}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontWeight: 'normal', color: 'text.secondary' }}>ID: {data.id}</Typography>
              <CopyIconButton fn={(copy) => copy(data.id)} />
            </Stack>
            <Typography component="h2" variant="h4">
              On Demand {data?.providerType?.name} Appointment
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={-0.5}>
            <Typography>
              <Skeleton width={100} />
            </Typography>
            <Typography component="h2" variant="h4">
              <Skeleton width={300} />
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={spacing}>
          {showData ? <DetailsSection data={data} /> : <DetailsSection.Loading />}
          {data?.status === OnDemandStatus.Accepted && <AppointmentSection appointmentId={data.appointmentId} />}
          <PatientSection patientId={data?.userId} />
          <NotesSection appointmentId={data?.appointmentId} readOnly={!canAddNotes} />
        </Stack>
      </DialogContent>
    </>
  )
}
