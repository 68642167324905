import { useCallback } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export const NotificationSetting = {
  Sound: 'sound',
  GeneralDuringVisit: 'generalDuringVisit',
  OnDemandAppointmentsDuringVisit: 'onDemandAppointmentsDuringVisit',
}

export default function useNotificationSettings() {
  const [settings, setSettings] = useLocalStorage('notification-settings', {
    [NotificationSetting.Sound]: true,
    [NotificationSetting.GeneralDuringVisit]: false,
    [NotificationSetting.OnDemandAppointmentsDuringVisit]: true,
  })

  const updateSettings = useCallback(
    (settings = {}) => {
      setSettings((current) => ({ ...current, ...settings }))
    },
    [setSettings]
  )

  return [settings, updateSettings]
}
